.about {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 1rem;
}

.about-container {
  display: flex;
  width: 100vw;
  justify-content: center;
}

.about-description {
  max-width: 537px;
  min-height: 500px;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0rem 4rem;
}

.about-description h1 {
  font-family: var(--font-title);
  font-size: 40px;
  line-height: 125.2%;
  letter-spacing: 0.015em;
}

.about-description p {
  font-family: var(--font-family);
  color: var(--color-body);
  font-size: 18px;
  line-height: 124.47%;
  max-width: 537px;
  margin: 1rem 0rem 0rem 0rem;
}

.boston-university {
  color: var(--color-highlight);
}

@media screen and (max-width: 1260px) {
  .about-container {
    flex-direction: column;
  }

  .about-description {
    align-self: center;
    margin: 0rem 1rem;
  }
}

@media screen and (max-width: 590px) {
  .about-description h1 {
    font-family: var(--font-title);
    font-size: 32px;
    line-height: 144.2%;
    margin: 0rem 0rem 1rem 0rem;

    letter-spacing: 0.015em;
  }

  .about-description p {
    margin: 1rem 0rem;
  }
}
