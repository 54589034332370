.projects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem 1rem;
  /* background-color: blue; */
}

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.note {
  /* background-color: red; */
  position: relative;
  max-width: 575px;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: var(--color-subtext);
}
