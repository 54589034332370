.landing {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
}

.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.landing-container-top p {
  color: var(--color-text);
  font-family: var(--font-special);
  font-size: 36px;
  line-height: 43px;
  text-align: center;
}

.landing-container-middle p {
  color: var(--color-text);
  font-family: var(--font-title);
  font-size: clamp(24px, 10vw, 144px);
  line-height: 175px;
  letter-spacing: 0.015em;
  text-align: center;
}

.landing-container-bottom p {
  color: var(--color-subtext);
  font-family: var(--font-special);
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

@media screen and (max-width: 690px) {
  .landing-container-top p {
    font-size: 20px;
    line-height: 24px;
  }

  .landing-container-middle p {
    font-size: 64px;
    line-height: 78px;
  }

  .landing-container-bottom p {
    font-size: 14px;
    line-height: 17px;
  }
}
