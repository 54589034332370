.draggable-container {
  max-width: 477px;
  max-height: 477px;
  margin: 0rem 2rem;
  align-self: center;
  filter: drop-shadow(10px 10px 24px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
  z-index: -1;
}

@media screen and (max-width: 1260px) {
  .draggable {
    max-width: 537px;
    margin: 0rem 1rem;
  }
}
