.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  background-color: var(--color-bg);
}

.navbar-links {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-links-left {
  display: flex;
  flex-direction: row;
}

.navbar-links-center {
  display: flex;
  flex-direction: row;
}

.navbar-links-right {
  display: flex;
  flex-direction: row;
}

.navbar-links-right p,
.navbar-links-center p,
.navbar-links-left p {
  color: #000000;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 22px;

  margin: 0 1rem;
  cursor: pointer;
}

.navbar-mobile-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.navbar-mobile-menu p {
  color: var(--color-text);
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 22px;

  margin: 0 1rem;
  cursor: pointer;
}

.navbar-menu-container-links p {
  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 22px;

  margin: 0 1rem;
  cursor: pointer;
}

.navbar-menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: var(--color-body);
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 180px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.navbar-menu-container p {
  margin: 1rem 0;
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }

  .navbar-menu-container {
    top: 20px;
  }

  .navbar-links-left {
    display: none;
  }

  .navbar-links-right {
    display: none;
  }

  .navbar-mobile-menu {
    display: flex;
  }
}
